
























import { Vue, Prop, Component } from 'vue-property-decorator';
// import ElementUI from 'element-ui';
// Vue.use(ElementUI);
@Component
export default class NaFooter extends Vue {
  private f1 = 'Copyright 2020 © CnTouch Florist. Powered by Intimex.';
  private f2 = 'Powered by Intimex'
}
